import React from 'react';
import SendFormData from "../../server/sendFormData";
import Form from "react-jsonschema-form";
import FormTools from '../common/formTools';

//import { MdNavigateNext } from 'react-icons/md';
import { Translation, Trans} from 'react-i18next';

import '../../assets/css/form.css';
import getLanguage from '../../language/getLanguage';
const lang = getLanguage();

import variables from "../../variables/env.js";




class Registro extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            error : null,
            isLoaded : true,
            data:[],
            submitMessage:null,
            submitStatus:null,
            urlVerificacion:null,
            redirectToIvenes: true, //(variables.mienv === 'wws' || variables.mienv === 'wwscom') ? true : false,
        }

    }

    customFieldTemplate = (props)=>{
        const {id, classNames, label, help, required, description, errors, children, hidden} = props;
        return (
          <Translation ns={['registro']}>
          {
            (t) =>
            <div className={classNames}>
              {!hidden && label && <label htmlFor={id}>{t(label)}
              {required && <span className="required"> ({t('dato obligatorio')})</span>}
              {!required &&<span className="norequired"> ({t('opcional')})</span>}
              </label>}

                  {description}
                  {children}
                  {help}
                  {errors.props && errors.props.errors && errors.props.errors.length > 0 && errors.props.errors.map((k,index) => {
                      return  <div key={index} className="text-danger small">
                          {t(''+errors.props.errors[index])}
                      </div>
                    })
                  }
            </div>
          }
          </Translation>
        );
    }

    onChange = ({formData}) => {
        let d = this.state.data;
        Object.keys(formData).map((key) => {
            if (formData[key] == null || formData[key] == undefined) { formData[key] = ''; }
            if (d.result.schema.properties[key] && d.result.schema.properties[key]['properties'] && typeof formData[key]==='object'){
                Object.keys(formData[key]).map((k, idx) => {
                    if (formData[key][k] == null || formData[key][k] == undefined) { formData[key][k] = ''; }
                    if (d.result.schema.properties[key]['properties'][k]){
                        d.result.schema.properties[key]['properties'][k]['default'] = formData[key][k];
                    }
                });
            }else if (d.result.schema.properties[key]){
              d.result.schema.properties[key].default = formData[key];
              // if  (key.includes('nombre') || key.includes('apellidos')){
              //    if(formData[key].match(/^[a-zA-Z\s\b]*$/)){
              //      d.result.schema.properties[key].default = formData[key].toUpperCase();
              //    }
              // }else{
              //   d.result.schema.properties[key].default = formData[key];
              // }
            }
        });
        this.setState({data:d});
    }

    validate= (formData, errors) => {
      let strError = '';
      if (!formData.acepta_politicas) {
        errors.acepta_politicas.addError('Debe aceptar las políticas de privacidad');
      }
      if (formData.contrasena.password !== formData.contrasena.password2) {
        console.log('strError',strError);
        errors.contrasena.password.addError('Contraseñas diferentes');
      }

      return errors;
    }
    onError = (errors) => console.log("I have", errors.length, "errors to fix");
    onSubmit = ({formData}) => {
        this.setState({isLoaded: true});
                    
        SendFormData(formData, 'index.php/cliente/registrar')
        .then((data) => {
            this.setState({
              submitMessage : data.message,
              submitStatus : data.status
            });
            if (data.status = 'ok' && data.result && data.result.url ){
                window.location.href  = data.result.url;
            }else{
              this.setState({isLoaded: false});
            }
        });
      }

    componentDidMount(){

        fetch(variables.apiurl +'index.php/cliente/registroForm',{
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
              body: JSON.stringify({lang:lang})
        }).then( response => response.json())
        .then(
            // handle the result
            (result) => {
                if (result.status ==='ok'){
                  result.result.uiSchema.acepta_politicas = {
                    "ui:help": (
                      <div>
                        <a className="small d-block" href="politicasdeprivacidad" target="_blank">
                        {this.getStringTranslation('Leer políticas de privacidad')} <i
                            className="fa fa-external-link" aria-hidden="true"></i></a>
                      </div>
                    )
                  }

                    this.setState({

                        isLoaded: false,
                        data : result
                    });
                }else{
                    this.setState({
                        isLoaded: true,
                        error: result.message
                    })
                }
            },
            // Handle error
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                })
            },
        )
    }
    getStringTranslation(jsonElement){
      return (<Trans defaults={jsonElement} />)
    }//getStringTranslation

    render() {
        const {error, isLoaded, data, urlVerificacion,redirectToIvenes,submitStatus,submitMessage} = this.state;
        if(error || isLoaded) {
            return <div className="loading"><div className="spinner-border text-muted"></div></div>
        }else{
            return(

<Translation ns={['translation', 'login']}>
{
  (t) => <main id="wrapper" className="pt-md-5 pb-md-5" role="main">
            <div className="container">
                <div className="row justify-content-center">
                      <div className="col-12">
                          <div className="profile_wrap">
                              {/* CUSTOM APPNAME */}
                              { variables.appname === 'IntercomWWS' && <div className="result_header">
                                  <div className="result_logo">  <a><img src={variables.appurl +"assets/img/"+variables.mienv+"/logo-white.png"} alt={variables.sitename} /></a>  </div>
                              </div> }

                              {/* muestra el formulario si no ha sido enviado y no hay que redirigir a i-ven.es */}
                              {!urlVerificacion && !redirectToIvenes &&
                                    <div className="result_body">
                                       
                                        {submitStatus !=='ok' && error && <p className="lead font-weight-bold">{error}</p>}
                                       
                                        {submitMessage && <div dangerouslySetInnerHTML={{__html: submitMessage}} /> }
                                       
                                        {!submitMessage &&
                                          <h4 className="text-center font-weight-bold">{t('login:Registro.intro.intro')} <span className="bg-rojo3 pl-2 pr-2">{t('login:Registro.intro.destacado')}</span></h4>
                                        }
                                        <div className="orange_divider"></div>
                                        {data && data.status==='ok' && submitStatus!=='ok' &&
                                        <Form className="col-12 frmRegistro"
                                            schema={data.result.schema}
                                            uiSchema={data.result.uiSchema}
                                            transformErrors={FormTools.transformErrors}
                                            onChange={this.onChange}
                                            onSubmit={this.onSubmit}
                                            FieldTemplate={this.customFieldTemplate}
                                            validate={this.validate}
                                            showErrorList={true}>
                                              <div className="form-group form-button">
                                                <button type="submit" className="btn btn-outline-default">{t('login:Registro.boton')}</button>
                                              </div>
                                             
                                           
                                        </Form>
                                        }
                                    </div>
                              }
                              {/* muestra el texto de aceptación para redirigir a i-ven.es */}
                              {redirectToIvenes &&
                                    <div className="result_body">
                                      <p className="h4">🔔 AVISO DE REGISTRO 🔔

                                          Estimado usuario,

                                          Le informamos que actualmente ya no estamos registrando clientes a través de I-VEN. Si desea registrarse, será redireccionado a nuestra página web de INTERCOMWWS para completar su registro.

                                          ✅ Puede hacerlo con total confianza, ya que seguimos siendo los mismos y las condiciones del servicio no han cambiado.

                                          🙏 ¡Gracias por su comprensión!</p>

                                        <div className="login_wrap mt-5">
                                          <a className="accent_btn mt-0" href="https://intercomwws.es/registro">{t('Continuar')}</a>
                                        </div>
                                        <div className="orange_divider"></div>
                                    </div>
                              }
                            <div className="result_footer ">
                                <div className="polygon_item"></div>
                            </div>
                      </div>
                    </div>
                </div>
            </div>
          </main>
}
</Translation>

            )
        }
    }//end render
}
export default Registro;
